
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { useExcelParams, useMenores, useSearchFilter } from "@/composable";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { SortProps, sortFn } from "@/core/helpers/sort";
import Page from "@/layout/Page.vue";
import { AuthModule } from "@/store/modules";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useArrayPagination } from "vue-composable";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "menores-listado",
  components: { "download-excel": JsonExcel, Page },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const { menores, isLoading, error, fetch } = useMenores(comarcaId);

    // Sort.
    const sortProps = ref<SortProps>({
      prop: "createdOn",
      order: "descending",
      method: null,
    });
    const sortedCollection = computed(() => {
      return menores.value.slice().sort(sortFn(sortProps));
    });

    // Filter
    const { search, filteredCollection } = useSearchFilter(sortedCollection, [
      "nombreComarca",
      "responsable",
      "familiaDni",
      "nombreCompleto",
      "fechaNacimientoString",
    ]);

    // Export
    const { labels, fields, generateName } = useExcelParams(
      {
        ...(!comarcaId && { nombreComarca: t("csv.listados.menor.comarca") }),
        tratamientoValue: t("csv.listados.menor.tratamiento"),
        nombreCompleto: t("csv.listados.menor.menor"),
        fechaNacimientoString: t("csv.listados.menor.fechaNacimiento"),
        responsable: t("csv.listados.menor.responsable"),
        familiaDni: t("csv.listados.menor.familiaDni"),
        familiaEmail: t("csv.listados.familias.email"),
        familiaTelefono: t("csv.listados.familias.telefono"),
        familiaDireccion: t("csv.listados.familias.direccion"),
        familiaLocalidad: t("csv.listados.familias.localidad"),
        familiaProvincia: t("csv.listados.familias.provincia"),
        familiaPrioritaria: t(
          "tabla.serviciosSolicitadosListado.cabecera.familiaPrioritaria"
        ),
        familiaIngresos: t("formularios.familiaDetalle.ingresos.label"),
      },
      "menoresListado"
    );

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("menu.menores"), [
        t("menu.planCorresponsables"),
      ]);
    });

    fetch().then(() => {
      search.value = "";
      currentPage.value = 1;
    });

    const { result, currentPage, lastPage } = useArrayPagination(
      filteredCollection,
      { pageSize: 100 }
    );

    return {
      isLoading,
      error,
      menores: filteredCollection,
      handleEdit: (index, row) => router.push(`menores/${row.id}`),
      isAdmin: !comarcaId,
      labels,
      fields,
      generateName,
      search,
      fetch,
      result,
      currentPage,
      lastPage,
      handleCurrentPageChange: (page: number) => (currentPage.value = page),
      sortChange: ({ column, prop, order }) => {
        sortProps.value = { prop, order, method: column.sortMethod };
      },
    };
  },
});
